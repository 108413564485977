.presentation-card {
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0,0,0,0.1);
  }
}

.ant-btn {
  &:hover {
    background: var(--color-gray-100);
    color: var(--color-gray-900);
  }
} 
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;