.voice-search-input {
  .ant-input {
    border-radius: 8px;
    border-color: var(--color-primary-200);
    
    &:hover, &:focus {
      border-color: var(--color-primary-400);
    }
  }
}

.voice-card {
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  }
}

.play-sample-button {
  &:hover {
    background: var(--color-primary-100) !important;
    color: var(--color-primary-700) !important;
  }
} 
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;