.disabled {
    pointer-events: none; /* Disable interactions */
    opacity: 0.5; /* Make it look disabled */
    background-color: #f9f9f9;
  }

.transition-row {
  height: 100%;
  overflow: auto;
  background-color: var(--color-primary-50);
  padding: 16px;

  .section-title {
    color: var(--color-primary-800);
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 6px;

    .anticon {
      color: var(--color-primary-600);
      font-size: 16px;
    }
  }

  .section-subtitle {
    color: var(--color-primary-700);
    font-family: 'DM Sans', sans-serif;
    font-size: 13px;
    font-weight: 500;
    margin: 16px 0 8px;
  }

  .transition-select,
  .duration-input {
    background: white;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

    .ant-input-number {
      width: 100%;
      border-color: var(--color-primary-200);

      &:hover, &:focus {
        border-color: var(--color-primary-400);
      }
    }
  }

  .switch-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    background: white;
    border-radius: 12px;
    margin-bottom: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

    .switch-label {
      color: var(--color-primary-800);
      font-family: 'DM Sans', sans-serif;
      font-size: 14px;
      font-weight: 500;
    }

    .ant-switch {
      background: var(--color-primary-200);
      
      &.ant-switch-checked {
        background: var(--color-primary-600);
      }
    }
  }

  .custom-sound-section {
    background: white;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    .input-row {
      display: flex;
      gap: 8px;
      margin-bottom: 16px;

      .ant-input {
        border-color: var(--color-primary-200);
        border-radius: 8px;

        &:hover, &:focus {
          border-color: var(--color-primary-400);
        }
      }

      .generate-button {
        background: var(--gradient-primary);
        border: none;
        border-radius: 8px;
        color: white;
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 4px;
        transition: all 0.2s ease;

        &:hover:not(:disabled) {
          transform: translateY(-1px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }

        &:disabled {
          opacity: 0.7;
          background: var(--color-primary-300);
        }
      }
    }

    .generated-sounds {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .sound-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 12px;
        background: var(--color-primary-50);
        border-radius: 8px;
        border: 1px solid var(--color-primary-100);

        .sound-info {
          display: flex;
          align-items: center;
          gap: 8px;
          color: var(--color-primary-800);
          font-size: 13px;

          .play-icon {
            color: var(--color-primary-600);
            cursor: pointer;
            transition: transform 0.2s ease;

            &:hover {
              transform: scale(1.1);
            }
          }
        }

        .use-button {
          background: var(--color-primary-600);
          border: none;
          border-radius: 6px;
          color: white;
          font-size: 12px;
          padding: 4px 12px;
          height: auto;
          transition: all 0.2s ease;

          &:hover:not(:disabled) {
            background: var(--color-primary-700);
          }

          &:disabled {
            background: var(--color-primary-200);
            color: var(--color-primary-600);
          }
        }
      }
    }
  }
}
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;