.video-panel {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .ant-tabs-nav {
      margin: 0 0 12px 0;
      padding: 0 12px;
      flex-shrink: 0;
      
      .ant-tabs-nav-list {
        display: flex;
        width: 100%;
        background-color: var(--color-primary-50);
        border-radius: 6px;
        padding: 4px;
        gap: 4px;

        .ant-tabs-tab {
          flex: 1;
          min-width: auto;
          padding: 6px 8px;
          margin: 0;
          border-radius: 4px;
          transition: all 0.3s ease;
          min-height: 32px;
          display: flex;
          justify-content: center;
          white-space: nowrap;
          
          .ant-tabs-tab-btn {
            font-size: 13px;
            color: var(--color-primary-700);
            display: flex;
            align-items: center;
            gap: 4px;
            
            &:hover {
              color: var(--color-primary-600);
            }

            span {
              display: flex;
              align-items: center;
              gap: 4px;
              white-space: nowrap;
            }
          }
          
          &.ant-tabs-tab-active {
            background-color: white;
            
            .ant-tabs-tab-btn {
              color: var(--color-primary-600);
              font-weight: 500;
            }
          }
        }
      }
      
      .ant-tabs-ink-bar {
        display: none;
      }
    }

    .ant-tabs-content-holder {
      flex: 1;
      overflow: hidden;
    }

    .ant-tabs-content {
      height: 100%;
      overflow: auto;
    }
  }

  .ant-input {
    border-radius: 6px;
    border: 1px solid var(--color-primary-100);
    
    &:hover, &:focus {
      border-color: var(--color-primary-400);
      box-shadow: none;
    }
  }

  .ant-collapse {
    background: transparent;
    
    .ant-collapse-item {
      border: none;
      
      .ant-collapse-header {
        padding: 8px 0;
        color: var(--color-primary-700);
        font-size: 13px;
      }
      
      .ant-collapse-content {
        background: transparent;
      }
    }
  }

  .ant-select {
    .ant-select-selector {
      border-radius: 6px;
      border: 1px solid var(--color-primary-100);
      
      &:hover {
        border-color: var(--color-primary-400);
      }
    }
  }

  .upload-button {
    border-radius: 6px;
    border: 1px dashed var(--color-primary-300);
    background-color: var(--color-primary-50);
    color: var(--color-primary-700);
    height: 40px;
    
    &:hover {
      color: var(--color-primary-600);
      border-color: var(--color-primary-400);
    }
    
    &.loading {
      opacity: 0.7;
    }
  }
} 
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;