.video-trimmer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  .video-preview {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    background: #000;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    .video-controls {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      background: linear-gradient(transparent, rgba(0,0,0,0.8));
      display: flex;
      justify-content: center;

      .play-pause-button {
        color: white;
        font-size: 40px;
        opacity: 0.9;
        transition: all 0.3s ease;

        &:hover {
          opacity: 1;
          transform: scale(1.1);
        }
      }
    }
  }

  .trimmer-controls {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .time-display {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: #333;
      font-family: monospace;
      font-weight: 500;
    }

    .trimmer-slider {
      padding: 0 12px;

      :global {
        .ant-slider {
          margin: 0;
        }

        .ant-slider-rail {
          height: 6px;
          border-radius: 3px;
        }

        .ant-slider-track {
          height: 6px;
          border-radius: 3px;
        }

        .ant-slider-handle {
          width: 24px;
          height: 24px;
          margin-top: -9px;
          border: 2px solid #1890ff;
          background: white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          transition: all 0.2s ease;

          &:hover {
            transform: scale(1.1);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
          }

          &:focus {
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
          }
        }

        .ant-slider-handle-1 {
          margin-left: -12px;
        }

        .ant-slider-handle-2 {
          margin-left: -12px;
        }

        .ant-tooltip {
          font-family: monospace;
        }
      }

      .custom-handle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        .anticon {
          font-size: 14px;
          color: #1890ff;
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 8px;

      .ant-btn {
        min-width: 100px;
        height: 40px;
        font-size: 14px;
        border-radius: 6px;
        transition: all 0.2s ease;

        &:hover {
          transform: translateY(-1px);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
} 
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;