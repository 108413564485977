.upload-image {
  padding: 16px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

  .header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    h3 {
      color: var(--color-primary-800);
      font-family: 'DM Sans', sans-serif;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }

    .ant-switch {
      background: var(--color-primary-200);
      
      &.ant-switch-checked {
        background: var(--color-primary-600);
      }
    }
  }

  .preview-section {
    margin-bottom: 20px;

    .ant-image {
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    }
  }

  .upload-section {
    margin-bottom: 20px;

    h3 {
      color: var(--color-primary-800);
      font-family: 'DM Sans', sans-serif;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 12px;
    }

    .ant-upload {
      width: 100%;
    }

    .ant-btn {
      width: 100%;
      height: 40px;
      border-radius: 8px;
      background: var(--color-primary-50);
      border: 1px solid var(--color-primary-200);
      color: var(--color-primary-600);
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      transition: all 0.2s ease;

      &:hover {
        background: var(--color-primary-100);
        border-color: var(--color-primary-300);
      }

      .anticon {
        font-size: 16px;
      }
    }

    .file-name {
      margin-top: 8px;
      color: var(--color-primary-600);
      font-size: 13px;
      font-family: 'DM Sans', sans-serif;
    }
  }

  .text-over-section {
    margin-bottom: 20px;

    .section-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      h3 {
        color: var(--color-primary-800);
        font-family: 'DM Sans', sans-serif;
        font-size: 14px;
        font-weight: 500;
        margin: 0;
      }

      .ant-switch {
        background: var(--color-primary-200);
        
        &.ant-switch-checked {
          background: var(--color-primary-600);
        }
      }
    }

    .ant-input {
      border-radius: 8px;
      border-color: var(--color-primary-200);
      padding: 8px 12px;
      transition: all 0.2s ease;

      &:hover, &:focus {
        border-color: var(--color-primary-400);
      }

      &::placeholder {
        color: var(--color-primary-400);
      }
    }
  }

  .save-button {
    width: 100%;
    height: 40px;
    background: var(--gradient-primary);
    border: none;
    border-radius: 8px;
    color: white;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: all 0.2s ease;

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    .anticon {
      font-size: 16px;
    }
  }
} 
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;