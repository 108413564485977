.crop-container {
  width: 100%;
  background: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;

  .cropper-container {
    width: 100% !important;
    height: 400px !important;
  }

  .loading-container {
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    background: #f5f5f5;

    .loading-text {
      font-size: 16px;
      color: #666;
    }
  }

  .loading {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #666;
    background: #f5f5f5;
  }
}

:global {
  .cropper-view-box {
    outline: 2px solid #1890ff;
    outline-color: rgba(24, 144, 255, 0.5);
  }

  .cropper-point {
    background-color: #1890ff;
  }

  .cropper-line {
    background-color: #1890ff;
  }
} 
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;