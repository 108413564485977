.scenes-container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .sections-row {
    display: flex;
    gap: 16px;
    padding: 12px 0;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--color-primary-200) transparent;
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-primary-200);
      border-radius: 3px;
    }

    .section-card {
      background: white;
      border-radius: 8px;
      padding: 12px;
      cursor: pointer;
      transition: all 0.2s ease;
      border: 1px solid var(--color-gray-200);
      width: 280px;
      flex: 0 0 280px;
      position: relative;
      overflow: hidden;

      &:hover {
        border-color: var(--color-primary-300);
        box-shadow: 0 2px 8px rgba(0,0,0,0.05);
      }

      &.selected {
        border-color: var(--color-primary-500);
        background: var(--color-primary-50);
        box-shadow: 0 2px 8px rgba(0,0,0,0.1);

        .section-header {
          .section-title {
            color: var(--color-primary-700);
          }
        }
      }

      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .section-title {
          font-weight: 500;
          color: var(--color-gray-900);
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .section-actions {
          display: flex;
          align-items: center;
          gap: 4px;

          .edit-button {
            color: var(--color-gray-600);
            padding: 4px;
            border-radius: 4px;
            transition: all 0.2s ease;

            &:hover {
              color: var(--color-primary-500);
              background: var(--color-primary-50);
            }
          }
        }
      }

      .captions-container {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        max-height: 60px;
        overflow-y: auto;
        padding-right: 4px;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: var(--color-gray-100);
          border-radius: 2px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--color-gray-300);
          border-radius: 2px;
        }

        .word-tag {
          background: var(--color-gray-100);
          padding: 2px 8px;
          border-radius: 4px;
          font-size: 12px;
          color: var(--color-gray-700);
          cursor: default;
        }

        .trimmed-words {
          font-size: 12px;
          color: var(--color-gray-500);
          font-style: italic;
          width: 100%;
        }
      }
    }
  }

  .audio-spectrum {
    margin-top: 0;
    padding: 6px;
    background: white;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    .audio-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .audio-title {
        font-size: 12px;
        font-weight: 500;
        color: var(--color-primary-900);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
      }

      .audio-controls {
        display: flex;
        align-items: center;
        gap: 8px;

        .play-button {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: var(--color-primary-500);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover {
            background: var(--color-primary-600);
            transform: scale(1.05);
          }

          .anticon {
            color: white;
            font-size: 14px;
          }
        }

        .audio-duration {
          font-size: 11px;
          color: var(--color-primary-600);
        }
      }
    }

    .wave-container {
      height: 24px;
      background: var(--color-primary-50);
      border-radius: 3px;
      overflow: hidden;
      position: relative;
      padding: 2px;

      .wave {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: repeating-linear-gradient(
          90deg,
          var(--color-primary-500) 0%,
          var(--color-primary-500) 1%,
          transparent 1%,
          transparent 3%
        );
        opacity: 0.3;
        transform-origin: bottom;
      }

      .wave::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: repeating-linear-gradient(
          90deg,
          var(--color-primary-500) 0%,
          var(--color-primary-500) 1%,
          transparent 1%,
          transparent 5%
        );
        opacity: 0.3;
        transform: translateY(30%);
      }

      .wave::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: repeating-linear-gradient(
          90deg,
          var(--color-primary-500) 0%,
          var(--color-primary-500) 1%,
          transparent 1%,
          transparent 4%
        );
        opacity: 0.3;
        transform: translateY(60%);
      }

      &.playing {
        .wave {
          animation: barWave 1s ease-in-out infinite;
        }

        .wave::before {
          animation: barWave 1s ease-in-out infinite 0.2s;
        }

        .wave::after {
          animation: barWave 1s ease-in-out infinite 0.4s;
        }
      }
    }
  }
}

@keyframes barWave {
  0% {
    transform: scaleY(0.3);
  }
  50% {
    transform: scaleY(0.7);
  }
  100% {
    transform: scaleY(0.3);
  }
}

.drag-handle {
  position: absolute;
  top: 0;
  right: -2px;
  width: 4px;
  height: 100%;
  cursor: ew-resize;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;

  .handle-line {
    width: 2px;
    height: 100%;
    background: var(--color-primary-500);
    border-radius: 1px;
  }
}

.scene-info {
  padding: 4px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.95);
  border-top: 1px solid var(--color-gray-200);
  height: 24px;

  .duration {
    font-size: 12px;
    font-weight: 600;
    color: var(--color-gray-900);
    background: var(--color-primary-50);
    padding: 2px 6px;
    border-radius: 4px;
  }

  .time-range {
    font-size: 11px;
    color: var(--color-gray-700);
    background: var(--color-gray-50);
    padding: 2px 6px;
    border-radius: 4px;
  }
}

.preview-container {
  height: 40px;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid var(--color-gray-100);
} 
  

@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;