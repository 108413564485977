.settings-container {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    .left-section {
      display: flex;
      align-items: center;
      gap: 20px;

      .divider {
        width: 1px;
        height: 30px;
        background: linear-gradient(to bottom, transparent, var(--color-primary-200), transparent);
      }

      .tabs-container {
        .ant-tabs-nav {
          margin-bottom: 0;
        }

        .ant-tabs-tab {
          padding: 8px 12px;
          margin: 0 4px;
          border-radius: 8px;
          transition: all 0.2s ease;

          &:hover {
            background: var(--color-primary-50);
          }

          &.ant-tabs-tab-active {
            background: var(--color-primary-100);
          }

          .tab-content {
            display: flex;
            align-items: center;
            gap: 8px;

            h4 {
              margin: 0;
              font-size: 13px;
              font-weight: 500;
              color: var(--color-primary-900);
            }

            .icon-wrapper {
              display: flex;
              align-items: center;
              gap: 4px;
              color: var(--color-primary-900);
              cursor: pointer;
              transition: opacity 0.2s ease;

              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }

    .toggle-button {
      padding: 8px;
      border: 2px solid var(--color-primary-500);
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: var(--color-primary-50);
      }
    }
  }

  .content-divider {
    height: 1px;
    background: linear-gradient(to right, transparent, var(--color-primary-200), transparent);
    margin: 8px 0;
  }

  .tab-content {
    flex: 1;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    &.entering {
      opacity: 0;
      transform: translateY(-10px);
    }

    &.entered {
      opacity: 1;
      transform: translateY(0);
    }

    &.exiting {
      opacity: 0;
      transform: translateY(-10px);
    }

    &.exited {
      opacity: 0;
      transform: translateY(-10px);
    }
  }
} 
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;