.captions-panel {
  height: 100%;
  overflow: auto;
  background-color: var(--color-primary-50);
  padding: 16px;

  .header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 12px 16px;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

    h3 {
      color: var(--color-primary-800);
      font-family: 'DM Sans', sans-serif;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
    }

    .ant-switch {
      background: var(--color-primary-200);
      
      &.ant-switch-checked {
        background: var(--color-primary-600);
      }
    }
  }

  .section-title {
    color: var(--color-primary-800);
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 6px;

    .anticon {
      color: var(--color-primary-600);
      font-size: 16px;
    }
  }

  .section-container {
    background: white;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

    .section-title {
      cursor: pointer;
      user-select: none;
      transition: color 0.2s ease;

      &:hover {
        color: var(--color-primary-600);
      }
    }
  }

  .color-settings {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    opacity: 0;

    &.visible {
      max-height: 500px;
      opacity: 1;
      transition: max-height 0.3s ease-in, opacity 0.2s ease-in;
    }

    .color-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 0;
      border-bottom: 1px solid var(--color-primary-100);

      &:last-child {
        border-bottom: none;
      }

      .color-input {
        width: 32px;
        height: 32px;
        padding: 0;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        transition: transform 0.2s ease;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .advanced-settings {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    opacity: 0;

    &.visible {
      max-height: 1000px;
      opacity: 1;
      transition: max-height 0.3s ease-in, opacity 0.2s ease-in;
    }

    .alert-section {
      margin-bottom: 16px;
      border-radius: 8px;
      background: var(--color-warning-50);
      border: 1px solid var(--color-warning-200);

      .ant-alert-message {
        font-size: 12px;
        color: var(--color-warning-800);
      }

      .ant-btn-link {
        padding: 0;
        height: auto;
        font-size: 12px;
        color: var(--color-primary-600);
        
        &:hover {
          color: var(--color-primary-700);
        }
      }
    }

    .caption-edit-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }

  .error-section {
    padding: 24px;
    text-align: center;
    background: white;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

    .ant-btn {
      background: var(--gradient-primary);
      border: none;
      border-radius: 8px;
      height: 40px;
      padding: 0 24px;
      color: white;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 16px auto;
      transition: all 0.2s ease;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }

      .anticon {
        font-size: 16px;
      }
    }
  }
}
  
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;