.scene-media {
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--color-white);
  border-left: 1px solid var(--color-gray-200);
  overflow: hidden;

  .sections-container {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .section-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    position: relative;
    transition: all 0.3s ease;

    &.selected-section {
      .section-header {
        background: var(--color-primary-50);
        border: 1px solid var(--color-primary-200);

        .section-title {
          color: var(--color-primary-700);
        }

        .section-linker {
          .linker-line {
            .linker-start,
            .linker-end {
              background: var(--color-primary-50);
              border-color: var(--color-primary-200);
              color: var(--color-primary-700);
            }

            .linker-connector {
              background: var(--color-primary-200);
            }
          }
        }
      }
    }

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      left: 24px;
      top: 100%;
      bottom: -12px;
      width: 2px;
      background: var(--color-gray-200);
    }
  }

  .section-header {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 12px;
    background: var(--color-gray-50);
    border-radius: 8px;
    position: relative;

    .section-linker {
      position: relative;
      width: 120px;
      padding-left: 24px;

      .linker-line {
        position: relative;
        display: flex;
        align-items: center;
        gap: 8px;

        &::before {
          content: '';
          position: absolute;
          left: -24px;
          top: 50%;
          width: 24px;
          height: 2px;
          background: var(--color-gray-200);
        }

        .linker-start,
        .linker-end {
          font-size: 12px;
          color: var(--color-gray-500);
          background: white;
          padding: 2px 6px;
          border-radius: 4px;
          border: 1px solid var(--color-gray-200);
        }

        .linker-connector {
          flex: 1;
          height: 2px;
          background: var(--color-gray-200);
        }
      }
    }

    .section-title {
      font-weight: 600;
      color: var(--color-primary-600);
      font-size: 14px;
      flex: 1;
    }

    .time-range {
      font-size: 12px;
      color: var(--color-gray-500);
    }
  }

  .media-scroll-container {
    display: flex;
    gap: 16px;
    overflow-x: auto;
    padding: 8px 0;
    scrollbar-width: thin;
    scrollbar-color: var(--color-gray-300) transparent;
    min-height: 320px;

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-gray-300);
      border-radius: 3px;
    }
  }

  .media-card {
    flex: 0 0 200px;
    cursor: pointer;
    transition: all 0.3s ease;
    height: 300px;
    user-select: none;

    &.dragging {
      opacity: 0.8;
      transform: scale(1.05);
      box-shadow: 0 8px 16px rgba(0,0,0,0.1);
      z-index: 1000;
    }

    &:hover {
      transform: translateY(-2px);
    }
  }

  .media-card-empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .preview-container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    background: var(--color-gray-100);
    display: flex;
    flex-direction: column;
  }

  .media-preview {
    position: relative;
    width: 100%;
    flex: 1;
    background: var(--color-gray-100);

    .preview-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .media-type-indicator {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    gap: 8px;
    z-index: 2;
    flex-direction: column;
    .edit-icon,
    .trim-icon,
    .crop-icon {
      background: white;
      padding: 4px;
      border-radius: 50%;
      cursor: pointer;
      transition: all 0.3s ease;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      &:hover {
        transform: scale(1.1);
        background: var(--color-primary-50);
      }
      .anticon {
        font-size: 16px;
        color: var(--color-primary-600);
      }
    }

    .video-icon,
    .image-icon {
      background: white;
      padding: 4px;
      border-radius: 50%;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      font-size: 16px;
      color: var(--color-primary-600);
      &:hover {
        transform: scale(1.1);
        background: var(--color-primary-50);
      }
    }
  }

  .scene-info {
    padding: 8px;
    background: white;
    border-top: 1px solid var(--color-gray-100);

    .time-range {
      font-size: 12px;
      color: var(--color-gray-500);
    }
  }

  .empty-state {
    padding: 32px;
    text-align: center;
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .add-media-card {
    flex: 0 0 200px;
    height: 300px;
    border: 2px dashed var(--color-gray-300);
    border-radius: 8px;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;

    &:hover {
      border-color: var(--color-primary-500);
      background: var(--color-primary-50);
    }

    .add-media-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;

      .add-icon {
        font-size: 24px;
        color: var(--color-gray-400);
      }

      .add-text {
        font-size: 14px;
        color: var(--color-gray-600);
      }
    }
  }

  :global {
    .ant-modal {
      max-width: 90vw;
      margin: 0 auto;
      padding: 0;

      .ant-modal-content {
        height: 90vh;
        display: flex;
        flex-direction: column;
      }

      .ant-modal-header {
        padding: 16px 24px;
        border-bottom: 1px solid var(--color-gray-200);
        margin: 0;
      }

      .ant-modal-body {
        flex: 1;
        overflow: auto;
        padding: 24px;
      }

      @media (max-width: 768px) {
        .ant-modal-content {
          height: 100vh;
        }

        .ant-modal-body {
          padding: 16px;
        }
      }
    }
  }
} 
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;