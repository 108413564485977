.script-panel-container {
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    padding: 16px 20px;
    border-bottom: 1px solid var(--color-primary-100);
    background: linear-gradient(to right, var(--color-primary-50), white);
    
    .title-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 4px;

      h1 {
        font-size: 18px;
        font-weight: 600;
        color: var(--color-primary-700);
        margin: 0;
        display: flex;
        align-items: center;
        gap: 8px;

        .anticon {
          font-size: 20px;
          color: var(--color-primary-500);
        }
      }

      .add-section-btn {
        background: var(--color-primary-50);
        border: 1px solid var(--color-primary-200);
        border-radius: 8px;
        color: var(--color-primary-600);
        padding: 8px;
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease;

        &:hover {
          background: var(--color-primary-100);
          transform: scale(1.05);
        }

        .anticon {
          font-size: 16px;
        }
      }
    }
  }

  .sections-container {
    flex: 1;
    overflow: hidden;
    padding: 20px;

    .sections-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 100%;
      overflow-y: auto;
      padding-right: 8px;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: var(--color-primary-50);
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--color-primary-200);
        border-radius: 3px;
        
        &:hover {
          background: var(--color-primary-300);
        }
      }
    }
  }

  .script-section {
    background: white;
    border-radius: 12px;
    border: 1px solid var(--color-primary-100);
    transition: all 0.2s ease;
    position: relative;
    
    &:hover {
      border-color: var(--color-primary-300);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    }

    &.selected {
      border: 2px solid var(--color-primary-500);
      background: linear-gradient(to bottom right, var(--color-primary-50), white);
      box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
    }

    .section-header {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--color-primary-100);

      .section-title {
        font-weight: 500;
        color: var(--color-primary-700);
        display: flex;
        align-items: center;
        gap: 8px;

        .drag-handle {
          color: var(--color-primary-400);
          cursor: grab;
          padding: 4px;
          border-radius: 4px;
          
          &:hover {
            background: var(--color-primary-50);
          }
        }
      }

      .section-actions {
        display: flex;
        align-items: center;
        gap: 8px;

        .play-button {
          color: var(--color-primary-600);
          font-size: 18px;
          cursor: pointer;
          transition: transform 0.2s ease;
          
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }

    .section-content {
      padding: 16px;

      .ant-input {
        border-radius: 8px;
        border: 1px solid var(--color-primary-200);
        padding: 12px;
        font-size: 14px;
        resize: none;
        transition: all 0.2s ease;
        
        &:hover, &:focus {
          border-color: var(--color-primary-400);
          box-shadow: 0 0 0 2px var(--color-primary-100);
        }
      }

      .voice-controls {
        margin-top: 12px;
        display: flex;
        gap: 8px;
        align-items: center;

        .ant-select {
          flex: 1;

          .ant-select-selector {
            border-radius: 8px;
            border: 1px solid var(--color-primary-200);
            padding: 4px 12px;
            height: 40px;
            
            &:hover {
              border-color: var(--color-primary-400);
            }
          }
        }

        .preview-button {
          padding: 8px;
          border-radius: 8px;
          color: var(--color-primary-600);
          
          &:hover {
            background: var(--color-primary-50);
          }
        }
      }

      .update-button {
        margin-top: 12px;
        width: 100%;
        height: 40px;
        border-radius: 8px;
        background: var(--color-primary-600);
        border: none;
        color: white;
        font-weight: 500;
        transition: all 0.2s ease;
        
        &:hover:not(:disabled) {
          background: var(--color-primary-700);
          transform: translateY(-1px);
        }
        
        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }
}

.voice-select-row {
  display: flex;
  align-items: center;
  gap: 8px;
  background: var(--color-gray-50);
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid var(--color-gray-200);
  width: 100%;

  .voice-icon {
    color: var(--color-primary-600);
    font-size: 16px;
    flex-shrink: 0;
  }

  :global {
    .ant-select {
      flex: 1;
      min-width: 200px;
      width: 100%;

      .ant-select-selector {
        width: 100% !important;
      }
    }
  }
} 
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;