.section-pages {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 0;

    .back-button {
      display: flex;
      align-items: center;
      gap: 8px;
      background: var(--color-primary-50);
      border: none;
      color: var(--color-primary-700);
      padding: 6px 12px;
      border-radius: 6px;
      transition: all 0.2s ease;

      &:hover {
        background: var(--color-primary-100);
        transform: translateY(-1px);
      }

      .anticon {
        font-size: 16px;
      }
    }

    h3 {
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      color: var(--color-primary-900);
    }
  }

  .scenes-row {
    display: flex;
    gap: 16px;
    padding: 12px 0;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--color-primary-200) transparent;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-primary-200);
      border-radius: 3px;
    }

   
  }
  .scene-card {
    position: relative;
    display: flex;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    flex-shrink: 0;

    .card-content {
      cursor: pointer;
      display: flex;
      justify-content: center;
      border: 1px solid var(--color-primary-200);
      width: 200px;
      min-width: 100px;
      height: 120px;
      padding: 0;
      overflow: hidden;
      transition: all 0.3s ease;
      position: relative;
      margin-right: 10px;
      border-radius: 8px;
      background: white;

      &.active {
        border: 2px solid var(--color-primary-500);
        background: var(--color-primary-50);
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }

      .preview-container {
        display: flex;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .preview-image {
          height: 100%;
          width: 100%;
          object-fit: cover;
          flex-shrink: 0;
          display: block;
        }
      }

      .scene-info {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: white;
        padding: 4px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--color-gray-100);
        z-index: 10;

        .duration {
          font-size: 12px;
          font-weight: 600;
          color: var(--color-primary-700);
          background: var(--color-primary-50);
          padding: 2px 6px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          gap: 4px;

          &::before {
            content: '';
            display: inline-block;
            width: 4px;
            height: 4px;
            background: var(--color-primary-500);
            border-radius: 50%;
          }
        }

        .time-range {
          font-size: 11px;
          color: var(--color-gray-600);
          background: var(--color-gray-50);
          padding: 2px 6px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          gap: 4px;

          &::before {
            content: '';
            display: inline-block;
            width: 4px;
            height: 4px;
            background: var(--color-gray-400);
            border-radius: 50%;
          }
        }
      }
    }

    .drag-handle {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 12px;
      cursor: ew-resize;
      background: linear-gradient(90deg, 
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0.2) 50%,
        rgba(0, 0, 0, 0.3) 100%
      );
      z-index: 1000;
      border-radius: 0 4px 4px 0;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s ease;

      &:hover {
        background: linear-gradient(90deg, 
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.3) 50%,
          rgba(0, 0, 0, 0.4) 100%
        );
      }

      .handle-line {
        width: 2px;
        height: 24px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 1px;
      }
    }

    &.add-card {
      padding: 12px!important;
      .card-content {
        width: 120px;
        height: 64px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
        background: var(--color-gray-50);
        border: 2px dashed var(--color-gray-300);
        cursor: pointer;
        transition: all 0.2s ease;
        margin-right: 10px;

        &:hover {
          background: var(--color-gray-100);
          border-color: var(--color-primary-300);
          transform: translateY(-2px);
        }

        .add-icon {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: var(--color-primary-50);
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--color-primary-500);
          font-size: 16px;
          transition: all 0.2s ease;
        }

        .add-text {
          font-size: 12px;
          color: var(--color-gray-600);
          font-weight: 500;
        }
      }
    }
  }
  .audio-spectrum {
    margin-top: 8px;
    padding: 8px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }

    .audio-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;

      .audio-title {
        font-size: 12px;
        font-weight: 500;
        color: var(--color-primary-900);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
      }

      .audio-controls {
        display: flex;
        align-items: center;
        gap: 8px;

        .play-button {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: var(--color-primary-500);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover {
            background: var(--color-primary-600);
            transform: scale(1.05);
          }

          .anticon {
            color: white;
            font-size: 14px;
          }
        }

        .audio-duration {
          font-size: 11px;
          color: var(--color-primary-600);
        }
      }
    }

    .wave-container {
      height: 24px;
      background: var(--color-primary-50);
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      padding: 2px;

      .wave {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: repeating-linear-gradient(
          90deg,
          var(--color-primary-500) 0%,
          var(--color-primary-500) 1%,
          transparent 1%,
          transparent 3%
        );
        opacity: 0.3;
        transform-origin: bottom;
      }

      .wave::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: repeating-linear-gradient(
          90deg,
          var(--color-primary-500) 0%,
          var(--color-primary-500) 1%,
          transparent 1%,
          transparent 5%
        );
        opacity: 0.3;
        transform: translateY(30%);
      }

      .wave::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: repeating-linear-gradient(
          90deg,
          var(--color-primary-500) 0%,
          var(--color-primary-500) 1%,
          transparent 1%,
          transparent 4%
        );
        opacity: 0.3;
        transform: translateY(60%);
      }

      &.playing {
        .wave {
          animation: barWave 1s ease-in-out infinite;
        }

        .wave::before {
          animation: barWave 1s ease-in-out infinite 0.2s;
        }

        .wave::after {
          animation: barWave 1s ease-in-out infinite 0.4s;
        }
      }
    }
  }

  .video-trimmer {
    width: 100%;
    padding-top: 16px;

    .title {
      color: var(--color-primary-500);
      font-weight: 500;
      margin-left: 4px;
      margin-bottom: 12px;
      font-size: 14px;
    }
  }
}

@keyframes barWave {
  0% {
    transform: scaleY(0.3);
  }
  50% {
    transform: scaleY(0.7);
  }
  100% {
    transform: scaleY(0.3);
  }
} 

.scenes-row {
  display: flex;
  gap: 8px;
  padding: 8px;
  overflow-x: auto;
  overflow-y: hidden;
  height: 80px;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: var(--color-gray-100);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--color-gray-300);
    border-radius: 2px;
  }

  .scene-card {
    position: relative;
    height: 64px;
    flex-shrink: 0;

    .card-content {
      height: 100%;
      border: 1px solid var(--color-gray-200);
      border-radius: 8px;
      overflow: hidden;
      cursor: pointer;
      transition: all 0.2s ease;
      background: white;

      &:hover {
        border-color: var(--color-primary-200);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
      }

      &.active {
        border-color: var(--color-primary-500);
        background: var(--color-primary-50);
      }

      .preview-container {
        height: 40px;
        overflow: hidden;
        position: relative;
        border-bottom: 1px solid var(--color-gray-100);
      }

      .scene-info {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background: white;
        padding: 4px 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--color-gray-100);
        z-index: 10;

        .duration {
          font-size: 12px;
          font-weight: 600;
          color: var(--color-primary-700);
          background: var(--color-primary-50);
          padding: 2px 6px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          gap: 4px;

          &::before {
            content: '';
            display: inline-block;
            width: 4px;
            height: 4px;
            background: var(--color-primary-500);
            border-radius: 50%;
          }
        }

        .time-range {
          font-size: 11px;
          color: var(--color-gray-600);
          background: var(--color-gray-50);
          padding: 2px 6px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          gap: 4px;

          &::before {
            content: '';
            display: inline-block;
            width: 4px;
            height: 4px;
            background: var(--color-gray-400);
            border-radius: 50%;
          }
        }
      }
    }

    .drag-handle {
      position: absolute;
      top: 0;
      right: -2px;
      width: 4px;
      height: 100%;
      cursor: ew-resize;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;

      .handle-line {
        width: 2px;
        height: 100%;
        background: var(--color-primary-500);
        border-radius: 1px;
      }
    }
  }
} 
    
  
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;