.voice-overs-wrapper {
  -webkit-transition: all 0.275s;
  transition: all 0.275s;

  .voice-overs {
    -webkit-transition: all 0.275s;
    transition: all 0.275s;
    max-height: 0;
    overflow: hidden;
    &.visible {
      max-height: 1000px;
      overflow: visible;
    }
  }
}

.voice-settings {
  -webkit-transition: all 0.275s;
  transition: all 0.275s;
  max-height: 0;
  overflow: hidden;
  background-color: 'white';
  &.visible {
    max-height: 1000px;
    overflow: visible;

  }
}
.background-settings {
  -webkit-transition: all 0.275s;
  transition: all 0.275s;
  max-height: 0;
  overflow: hidden;
  background-color: 'white';
  &.visible {
    max-height: 1000px;
    overflow: visible;

  }
}

// Tabs styling
.ant-tabs {
  .ant-tabs-nav {
    margin-bottom: 12px;
    padding: 0 12px;
    
    .ant-tabs-tab {
      padding: 8px 12px;
      margin: 0 8px 0 0;
      font-size: 13px;
      
      &.ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: var(--color-primary-600);
        }
      }
    }
  }
  
  .ant-tabs-content-holder {
    padding: 0 16px;
  }
}

// Common section styling
.section-container {
  padding: 0 12px;

  h3 {
    color: black;
    font-size: 14px;
    font-weight: 500;
    font-family: 'DM Sans';
    letter-spacing: normal;
    margin-bottom: 8px;
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }

  .tooltip-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .loading-indicator {
      color: var(--color-primary-600);
      margin-left: 8px;
    }

    .anticon {
      font-size: 16px;
      cursor: pointer;
      
      &:hover {
        color: var(--color-primary-600);
      }
    }
  }

  .voice-select,
  .ant-select,
  .ant-input {
    margin-bottom: 16px;
  }

  .ant-slider {
    margin: 8px 0 16px;
  }

  .switch-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;

    .ant-switch {
      margin-left: 12px;
    }
  }

  .upload-section,
  .clone-section {
    margin: 16px 0;

    .ant-upload {
      margin: 8px 0;
    }

    .action-button {
      width: 100%;
      margin-top: 8px;
    }
  }

  .voice-overs-section {
    margin-top: 16px;

    .voice-overs {
      display: none;
      padding: 8px;
      background-color: var(--color-primary-50);
      border-radius: 4px;
      margin-top: 8px;

      &.visible {
        display: block;
      }

      h3 {
        margin-top: 0;
        margin-bottom: 12px;
      }
    }
  }
}

// Form elements styling
.ant-select {
  margin-bottom: 12px;
}

.ant-slider {
  margin: 8px 0 16px;
}

.ant-input {
  margin-bottom: 8px;
}

.ant-upload {
  margin-bottom: 8px;
  
  .ant-upload-list {
    margin-top: 8px;
  }
}

// Switch alignment
.switch-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 8px 0;
  
  .ant-switch {
    margin-top: 0 !important;
  }
}

// Loading indicators
.loading-indicator {
  margin-left: 8px;
  color: var(--color-primary-600);
}

// Tooltips
.tooltip-label {
  display: flex;
  align-items: center;
  gap: 4px;
}

// Button styling
.action-button {
  width: 100%;
  margin-top: 8px;
  height: 32px;
  border-radius: 4px;
}

// Tag container
.tag-container {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

// Scrollbar styles
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.audio-panel {
  height: 100%;
  overflow: auto;
  background-color: var(--color-primary-50);
  padding: 16px;

  .ant-tabs {
    .ant-tabs-nav {
      margin-bottom: 16px;
      
      .ant-tabs-tab {
        padding: 8px 16px;
        
        &.ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            color: var(--color-primary-600);
          }
        }
      }
    }
  }

  .section-container {
    background: white;
    border-radius: 12px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);

    h3 {
      color: var(--color-primary-800);
      font-family: 'DM Sans', sans-serif;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      gap: 6px;
    }

    .tooltip-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;

      h3 {
        margin-bottom: 0;
      }

      .loading-indicator {
        color: var(--color-primary-600);
      }
    }

    .voice-select {
      margin-bottom: 16px;

      .ant-select-selector {
        border-radius: 8px;
        border-color: var(--color-primary-200);

        &:hover {
          border-color: var(--color-primary-400);
        }
      }
    }

    .ant-slider {
      margin: 8px 0 16px;

      .ant-slider-track {
        background-color: var(--color-primary-600);
      }

      .ant-slider-handle {
        border-color: var(--color-primary-600);

        &:hover {
          border-color: var(--color-primary-700);
        }
      }
    }

    .voice-overs-section {
      .voice-overs {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        opacity: 0;

        &.visible {
          max-height: 1000px;
          opacity: 1;
          transition: max-height 0.3s ease-in, opacity 0.2s ease-in;
        }
      }
    }

    .switch-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      border-bottom: 1px solid var(--color-primary-100);

      &:last-child {
        border-bottom: none;
      }

      .ant-switch {
        background: var(--color-primary-200);
        
        &.ant-switch-checked {
          background: var(--color-primary-600);
        }
      }
    }

    .upload-section {
      margin: 16px 0;

      .hoverable-text-box {
        margin-bottom: 12px;
        border-radius: 8px;
        border-color: var(--color-primary-200);

        &:hover {
          border-color: var(--color-primary-400);
        }
      }

      .ant-upload {
        margin-bottom: 12px;

        .ant-btn {
          border-radius: 8px;
          border-color: var(--color-primary-200);
          color: var(--color-primary-600);

          &:hover {
            border-color: var(--color-primary-400);
            color: var(--color-primary-700);
          }
        }
      }

      .action-button {
        width: 100%;
        border-radius: 8px;
        height: 40px;
        border: none;
        transition: all 0.2s ease;

        &:hover {
          transform: translateY(-1px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }

        &:disabled {
          opacity: 0.7;
          transform: none;
        }
      }
    }

    .clone-section {
      margin: 16px 0;

      .ant-select {
        margin-bottom: 16px;
        
        .ant-select-selector {
          border-radius: 8px;
          border-color: var(--color-primary-200);

          &:hover {
            border-color: var(--color-primary-400);
          }
        }
      }
    }
  }
}
@primary-color: #000000;@primary-logo-color: #00B837;@secondary-color: #FFFFFF;@cool-blue-white: #F0F4F8;@focus-bg: #F0F0F0;